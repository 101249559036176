<template>
  <div class="row">
    <div class="col">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <Confirm ref="confirm"/>
      <transition name="slide">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-auto">
                <h5 class="mt-2 mb-0">Registro de notas</h5>
              </div>
              <div class="col">
                <form class="form-inline float-right">
                  <input id="user" type="hidden" value="<?php echo $user->id ?>"/>
                  <ul class="nav justify-content-end">
                    <li class="nav-item">
                      <button v-if="hasRole('admin') || hasRole('admin-ub')" class="btn btn-outline-dark ml-2"
                              type="button"
                              @click="listarParametrosEditar(0);modalConfig = true">
                        <font-awesome-icon icon="fa-solid fa-gear"/>
                        Configurar
                      </button>
                    </li>
                    <li class="nav-item">
                      <button v-if="hasRole('admin') || hasRole('admin-ub')" class="btn btn-outline-dark ml-2"
                              type="button"
                              title="Configurar Fechas de registro de notas para los docentes"
                              @click="modalConfigFechas = true">
                        <font-awesome-icon icon="fa-solid fa-gear"/>
                        Fechas registro Notas
                      </button>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <label class="form-label" for="gestion"><strong>Gestión:</strong></label>
                <select class="form-control pl-1 pr-1" id="gestion"
                        v-model="gestion" required="required"
                        @change="actualizaCarreras();listarParametros(gestion)"
                >
                  <option value="" selected>:: SELECCIONAR ::</option>
                  <template v-for="(option,key) in gestiones">
                    <optgroup :label="key">
                      <option v-for="suboption in option" :value="suboption.value">
                        {{ suboption.label }}
                      </option>
                    </optgroup>
                  </template>
                </select>
              </div>
              <div class="col-4">
                <label for="alumnoCarrera"><strong>Carrera</strong></label>
                <select id="alumnoCarrera" required="required"
                        v-model="carrera_id" class="form-control"
                        @change="actualizaMaterias()">
                  <option value="0">:: SELECCIONAR ::</option>
                  <option v-for="option in carreras" :value="option.value">{{ option.label }}
                  </option>
                </select>
              </div>
              <div class="col-4">
                <strong>
                  <CSelect
                      :options="planes"
                      :plain="true"
                      :value.sync="plan_id"
                      label="Plan de Estudios"
                      required="required"
                      @change="actualizaMaterias()"
                  >
                  </CSelect>
                </strong>
              </div>
              <div class="col-4">
                <label class="form-label" for="gestion"><strong>Semestre</strong></label>
                <select class="form-control pl-1 pr-1" id="semeste"
                        v-model="semestre" required="required"
                        @change="materia_id=0"
                >
                  <option value="0" selected>:: SELECCIONAR ::</option>
                  <option v-for="(option,key) in semestres" :value="option">
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="col-4">
                <label for="materia"><strong>Materia</strong></label>
                <select id="materia" required="required"
                        v-model="materia_id" class="form-control"
                        @change="grupos=materias[semestre][materia_id]['grupos'];grupo=materias[semestre][materia_id]['grupos'][0]">
                  <option value="0">:: SELECCIONAR ::</option>
                  <option v-for="(option,key) in materias[semestre]" :value="key">{{ option.materia }}
                  </option>
                </select>
              </div>
              <div class="col-4">
                <strong>
                  <label for="grupo"><strong>Grupo</strong></label>
                  <select id="grupo" required="required"
                          v-model="grupo" class="form-control">
                    <option value="0" v-if="grupos.length!=1">:: SELECCIONAR ::</option>
                    <option v-for="option in grupos" :value="option" :selected="grupos.length==1">{{ option }}
                    </option>
                  </select>
                </strong>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col" v-if="mostrarParcial1">
                <div class="row">
                  <div class="col-2 px-1">
                    <input type="radio" name="parcial" id="parcial1" class="form-control" value="1" v-model="parcial">
                  </div>
                  <div class="col-10 pt-1"><label for="parcial1" :class="parcial==1?'text-success text-bold':''">Primer parcial</label>
                  </div>
                </div>
              </div>
              <div class="col" v-if="mostrarParcial2">
                <div class="row">
                  <div class="col-2 px-1">
                    <input type="radio" name="parcial" id="parcial2" class="form-control" value="2" v-model="parcial">
                  </div>
                  <div class="col-10 pt-1"><label for="parcial2" :class="parcial==2?'text-success text-bold':''">Segundo parcial</label>
                  </div>
                </div>
              </div>
              <div class="col" v-if="mostrarParcial3">
                <div class="row">
                  <div class="col-2 px-1">
                    <input type="radio" name="parcial" id="parcial3" class="form-control" value="3" v-model="parcial">
                  </div>
                  <div class="col-10 pt-1"><label for="parcial3" :class="parcial==3?'text-success text-bold':''">Tercer parcial</label>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col-2 px-1">
                    <input type="radio" name="parcial" id="examen_final" class="form-control" value="examen_final" v-model="parcial">
                  </div>
                  <div class="col-10 pt-1"><label for="examen_final" :class="parcial=='examen_final'?'text-success text-bold':''">Examen Final</label>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col-2 px-1">
                    <input type="radio" name="parcial" id="seg_instancia" class="form-control" value="seg_instancia" v-model="parcial">
                  </div>
                  <div class="col-10 pt-1"><label for="seg_instancia" :class="parcial=='seg_instancia'?'text-success text-bold':''">Segunda Instancia</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <parcialNuevo ref="frmparcialNuevo" v-if="materia_id > 0 && grupo > 0"
                    :gestion="gestion"
                    :carrera_id="carrera_id"
                    :plan_id="plan_id"
                    :materia_id="materia_id"
                    :grupo="grupo"
                    :parcial="parcial"
                    @actualizarParcial="actualizarParcial"
      ></parcialNuevo>
    </div>
    <CModal
        :centered="true"
        :closeOnBackdrop="false"
        :fade="true"
        :no-close-on-backdrop="true"
        :show.sync="modalConfig"
    >
      <template>
        <form ref="frmParametros" novalidate>
          <div class="row mb-2">
            <div class="col-6 text-center">
              <strong>Aplicar desde la Gestión</strong>
            </div>
            <div class="col-6">
              <select class="form-control pl-1 pr-1"
                      v-model="parametros.gestion" required="required"
                      @change="listarParametrosEditar(parametros.gestion)"
              >
                <option value="" selected>:: SELECCIONAR ::</option>
                <template v-for="(option,key) in gestiones">
                  <optgroup :label="key">
                    <option v-for="suboption in option" :value="suboption.value"
                    :class="parametros.gestiones.hasOwnProperty(suboption.value)?'text-danger text-bold':''">
                      {{ suboption.label }} {{parametros.gestiones.hasOwnProperty(suboption.value)?' (Parametros)':''}}
                    </option>
                  </optgroup>
                </template>
              </select>
            </div>
          </div>
          <hr/>
          <div class="row pl-2 mb-2">
            <div class="col-8 text-center">
              <strong>Nota mínima de para ingresar a 2T</strong>
            </div>
            <div class="col-4">
              <input type="text" v-model="parametros.nota_minima" class="form-control text-center" required>
            </div>
          </div>
          <div class="row pl-2 pt-2">
            <div class="col-8 text-center">
              <strong>Nota de aprobación (pregrado)</strong>
            </div>
            <div class="col-4">
              <input type="text" v-model="parametros.nota_aprobacion_pregrado" class="form-control text-center"
                     required>
            </div>
          </div>
          <div class="row pl-2 pt-2">
            <div class="col-8 text-center">
              <strong>Nota de aprobación (postgrado)</strong>
            </div>
            <div class="col-4">
              <input type="text" v-model="parametros.nota_aprobacion_postgrado" class="form-control text-center"
                     required>
            </div>
          </div>
          <div class="row pl-2 pt-2">
            <div class="col-8 text-center">
              <strong>Nota máxima por examen parcial 1</strong>
            </div>
            <div class="col-4">
              <input type="text" v-model="parametros.nota_maxima_parcial1" class="form-control text-center" required>
            </div>
          </div>
          <div class="row pl-2 pt-2">
            <div class="col-8 text-center">
              <strong>Nota máxima por examen parcial 2</strong>
            </div>
            <div class="col-4">
              <input type="text" v-model="parametros.nota_maxima_parcial2" class="form-control text-center" required>
            </div>
          </div>
          <div class="row pl-2 pt-2">
            <div class="col-8 text-center">
              <strong>Nota máxima por examen parcial 3</strong>
            </div>
            <div class="col-4">
              <input type="text" v-model="parametros.nota_maxima_parcial3" class="form-control text-center" required>
            </div>
          </div>
          <div class="row pl-2 pt-2">
            <div class="col-8 text-center">
              <strong>Nota máxima por examen final</strong>
            </div>
            <div class="col-4">
              <input type="text" v-model="parametros.nota_maxima_examenfinal" class="form-control text-center" required>
            </div>
          </div>
          <div class="row pl-2 pt-2">
            <div class="col-8 text-center">
              <strong>Nota máxima</strong>
            </div>
            <div class="col-4">
              <input type="text" v-model="parametros.nota_maxima" class="form-control text-center" required>
            </div>
          </div>
          <div class="row pl-2 pt-2">
            <div class="col text-center">
              <button type="button" @click="grabarParametros" class="btn btn-primary">
                Grabar parámetros
              </button>
            </div>
          </div>
        </form>
      </template>
      <template #header>
        <h4>Parámetros de Configuración <br/>Registro de Notas:
        </h4>
        <CButtonClose @click="modalConfig = false"/>
      </template>
      <template #footer-wrapper><span></span></template>
    </CModal>

    <CModal
        :centered="true"
        :closeOnBackdrop="false"
        :fade="true"
        :no-close-on-backdrop="true"
        :show.sync="modalConfigFechas"
    >
      <template>
        <form ref="frmFechasNotas" novalidate>
          <div class="row mb-2">
            <div class="col-4 text-center">
              <strong>Gestión</strong>
            </div>
            <div class="col-4">
              <select class="form-control pl-1 pr-1"
                      v-model="fechasnotas.gestion" required="required"
                      @change="actualizaFechasNotas()"
              >
                <option value="" selected>:: SELECCIONAR ::</option>
                <template v-for="(option,key) in gestiones">
                  <optgroup :label="key">
                    <option v-for="suboption in option" :value="suboption.value">
                      {{ suboption.label }}
                    </option>
                  </optgroup>
                </template>
              </select>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-4"></div>
            <div class="col-4">
              Fecha inicio registro de notas
            </div>
            <div class="col-4">
              Fecha finalización de registro de notas
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-4 text-center">
              <strong>Parcial 1</strong>
            </div>
            <div class="col-4">
              <input type="date" class="form-control" v-model="fechasnotas.parcial1_fecha_inicio"/>
            </div>
            <div class="col-4">
              <input type="date" class="form-control" v-model="fechasnotas.parcial1_fecha_fin"/>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-4 text-center">
              <strong>Parcial 2</strong>
            </div>
            <div class="col-4">
              <input type="date" class="form-control" v-model="fechasnotas.parcial2_fecha_inicio"/>
            </div>
            <div class="col-4">
              <input type="date" class="form-control" v-model="fechasnotas.parcial2_fecha_fin"/>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-4 text-center">
              <strong>Parcial 3</strong>
            </div>
            <div class="col-4">
              <input type="date" class="form-control" v-model="fechasnotas.parcial3_fecha_inicio"/>
            </div>
            <div class="col-4">
              <input type="date" class="form-control" v-model="fechasnotas.parcial3_fecha_fin"/>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-4 text-center">
              <strong>Examen Final</strong>
            </div>
            <div class="col-4">
              <input type="date" class="form-control" v-model="fechasnotas.examen_final_fecha_inicio"/>
            </div>
            <div class="col-4">
              <input type="date" class="form-control" v-model="fechasnotas.examen_final_fecha_fin"/>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-4 text-center">
              <strong>Segunda Instancia</strong>
            </div>
            <div class="col-4">
              <input type="date" class="form-control" v-model="fechasnotas.seg_instancia_fecha_inicio"/>
            </div>
            <div class="col-4">
              <input type="date" class="form-control" v-model="fechasnotas.seg_instancia_fecha_fin"/>
            </div>
          </div>

          <div class="row pl-2 pt-2">
            <div class="col text-center">
              <button type="button" @click="grabarFechasNotas" class="btn btn-primary">
                Grabar fechas de registro de notas
              </button>
              <br/>
              <em>Esta configuración se aplicará a todos los docentes de esta sede</em>
            </div>
          </div>
        </form>
      </template>
      <template #header>
        <h4>Parámetros de Configuración <br/>Fechas de Registro de Notas para docentes
        </h4>
        <CButtonClose @click="modalConfigFechas = false"/>
      </template>
      <template #footer-wrapper><span></span></template>
    </CModal>
  </div>
</template>

<script>
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import axios from "axios";
import Alerts from "@/views/notifications/Alerts";
import Confirm from "@/views/notifications/Confirm";
import Toast from "@/views/notifications/Toast";
import parcialNuevo from "@/views/docentematerianotas/parcialnuevo.vue";

var sourceLst;
var address;
var modalPdf;
var pagesizeoptions = [6, 10, 50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];

var btnNotas = function (sede_id, gestion, carrera_id, plan_id, materia_id, grupo, parcial, nombre_parcial, estado, estado_anterior) {
  let html = ''
  let clase = ''
  let grabar = ''
  if (estado === 'registrado') {
    html = '<span class="text-success ml-1 mr-1"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-circle-check fa-xl"><path fill="currentColor" d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" class=""></path></svg></span>';
    clase = 'btn-success'
    grabar = 'Actualizar'
  } else if (estado === 'no registrado') {
    if (estado_anterior === 'registrado' || estado_anterior === '') {
      html = '<span class="text-warning ml-1 mr-1 mt-1"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-circle-check fa-xl"><path fill="currentColor" d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" class=""></path></svg></span>';
    } else {
      html = '<span class="text-secondary ml-1 mr-1 mt-1"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-circle-check fa-xl"><path fill="currentColor" d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" class=""></path></svg></span>';
    }
    clase = 'btn-warning'
    grabar = 'Registrar'
  }
  return html + (estado_anterior === 'registrado' || estado_anterior === '' ? '<a class="btn ' + clase + ' pl-1 pt-1 pr-1 pb-0 mt-1" href="/#/docentematerianotas/' + sede_id + '/' + gestion + '/' + carrera_id + '/' + plan_id + '/' + materia_id + '/' + grupo + '/' + parcial + '/parcialnuevo" title="' + grabar + ' notas del ' + nombre_parcial + '">' + grabar + '</a>' : '');
}

export default {
  name: 'Docentes',
  components: {
    Toast,
    Confirm,
    Alerts,
    JqxGrid,
    jqxInput,
    parcialNuevo
  },
  data: () => {
    return {
      items: [],
      modalConfig: false,
      modalConfigFechas: false,
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      filasPorPagina: pagesizeoptions[0],
      sizeoptions: [],
      gestion: '',
      carrera_id: 0,
      plan_id: 0,
      gestiones: [],
      carreras: [],
      planes: [],
      semestres: [],
      semestre:'0',
      materias: [],
      materia_id:'0',
      grupos: [],
      grupo:'0',
      parcial:'0',
      mostrarParcial1:true,
      mostrarParcial2:true,
      mostrarParcial3:true,
      parametros: {
        gestion:'',
        gestiones:[],
        grupo: 'rangonotas',
        nota_minima: 35,
        nota_aprobacion_pregrado: 51,
        nota_aprobacion_postgrado: 65,
        nota_maxima_parcial1: 25,
        nota_maxima_parcial2: 25,
        nota_maxima_parcial3: 25,
        nota_maxima_examenfinal: 25,
        nota_maxima: 100
      },
      fechasnotas: {
        gestion: '',
        parcial1_fecha_inicio: '',
        parcial1_fecha_fin: '',
        parcial2_fecha_inicio: '',
        parcial2_fecha_fin: '',
        parcial3_fecha_inicio: '',
        parcial3_fecha_fin: '',
        examen_final_fecha_inicio: '',
        examen_final_fecha_fin: '',
        seg_instancia_fecha_inicio: '',
        seg_instancia_fecha_fin: ''
      },
      sedeSeleccionada: localStorage.sedeSeleccionada,
    }
  },
  beforeCreate: function () {
    address = this.$apiAdress;
  },
  methods: {
    hasRole(roleUser) {
      let roles = localStorage.getItem("roles");
      if (roles != null) {
        roles = roles.split(',')
        if (roles != null && roles.indexOf('admin') >= 0) {
          return true
        }
        return roles != null && roles.indexOf(roleUser) >= 0;
      }
    },

    nuevoBtnOnClick: function () {
      this.$router.push({path: '/docentemateria/' + this.$route.params.id + '/nuevo'});
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    sizeChange: function (event) {
      try {
        modalPdf.$refs.alert.show("Actualizando datos");
        this.$refs.gridSystem.pagesize = parseInt(this.filasPorPagina);
        this.$refs.gridSystem.updatebounddata();
      } catch (e) {}
    },
    eliminar: function (docenteid, id) {
      modalPdf.$refs.confirm('', 'Eliminar este registro', function (docenteid, id) {
        this.$refs.gridSystem.updatebounddata();
      })
    },
    actualizarDatos: function (event) {

    },
    actualizaCarreras: function (event) {
      modalPdf.carrera_id=0;
      modalPdf.carreras=[];
      modalPdf.semestre=0;
      modalPdf.semestres=[];
      modalPdf.grupo=0;
      modalPdf.grupos=[];
      axios.get(this.$apiAdress + '/api/docentematerianotas/' + localStorage.sedeSeleccionada + '/' + modalPdf.gestion + '/parametros?token=' + localStorage.getItem("api_token"))
          .then(function (response) {
            let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
            modalPdf.carreras = response.data.carreras;
            if (Object.keys(response.data.planes).length > 1) {
              modalPdf.planes = valorPorDefecto.concat(response.data.planes);
              modalPdf.plan_id = "";
            } else {
              modalPdf.planes = response.data.planes;
              modalPdf.plan_id = response.data.planes[0].value
            }
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    actualizaMaterias: function (event) {
      modalPdf.semestre=0;
      modalPdf.semestres=[];
      modalPdf.materia_id=0;
      modalPdf.materias=[]
      modalPdf.grupo=0;
      modalPdf.grupos=[]
      if (modalPdf.gestion !== '' && parseInt(modalPdf.carrera_id) >= 0 && parseInt(modalPdf.plan_id) >= 0) {
        axios.get(modalPdf.$apiAdress + '/api/docentematerianotas/' + localStorage.sedeSeleccionada + '/' + modalPdf.gestion + '/' + modalPdf.carrera_id + '/' + modalPdf.plan_id + '/listarmaterias?token=' + localStorage.getItem("api_token"))
            .then(function (response) {
              if (Object.keys(response.data.semestres).length > 0) {
                modalPdf.semestres = response.data.semestres;
                modalPdf.materias = response.data.materias;
                modalPdf.grupos = [];
              }
            })
            .catch(function (error) {
              console.log(error)
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            });
      }
    },
    listarParametros: function (gestion) {
      axios.get(this.$apiAdress + '/api/docentematerianotas/' + localStorage.sedeSeleccionada + '/' + gestion + '/listarrangonotas?token=' + localStorage.getItem("api_token"))
          .then(function (response) {
            modalPdf.parametros = response.data;
            if (Object.keys(response.data.planes).length > 1) {
              modalPdf.planes = valorPorDefecto.concat(response.data.planes);
              modalPdf.plan_id = "";
            } else {
              modalPdf.planes = response.data.planes;
              modalPdf.plan_id = response.data.planes[0].value
            }
            if(modalPdf.parametros.nota_maxima_parcial1<=0){
              //modalPdf.$refs.gridSystem.hidecolumn('parcial1');
              modalPdf.mostrarParcial1=false
            }else{
              //modalPdf.$refs.gridSystem.showcolumn('parcial1');
              modalPdf.mostrarParcial1=true
            }
            if(modalPdf.parametros.nota_maxima_parcial2<=0){
              //modalPdf.$refs.gridSystem.hidecolumn('parcial2');
              modalPdf.mostrarParcial2=false
            }else{
              //modalPdf.$refs.gridSystem.showcolumn('parcial2');
              modalPdf.mostrarParcial2=true
            }
            if(modalPdf.parametros.nota_maxima_parcial3<=0){
              //modalPdf.$refs.gridSystem.hidecolumn('parcial3');
              modalPdf.mostrarParcial3=false
            }else{
              //modalPdf.$refs.gridSystem.showcolumn('parcial3');
              modalPdf.mostrarParcial3=true
            }
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    listarParametrosEditar: function (gestion) {
      axios.get(this.$apiAdress + '/api/docentematerianotas/' + localStorage.sedeSeleccionada + '/' + gestion + '/listarrangonotaseditar?token=' + localStorage.getItem("api_token"))
          .then(function (response) {
            modalPdf.parametros = response.data;
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    grabarParametros: function (event) {
      if (!modalPdf.$refs.frmParametros.checkValidity()) {
        modalPdf.$refs.frmParametros.classList.add('was-validated');
      } else {
        modalPdf.modalConfig = false;
        modalPdf.$refs.alert.show('Grabando materias asignadas');
        axios.post(this.$apiAdress + '/api/docentematerianotas/' + localStorage.sedeSeleccionada + '/actualizaparametros?token=' + localStorage.getItem("api_token"),
            modalPdf.parametros
        )
            .then(function (response) {
              modalPdf.$refs.mensajeToast.makeToast('', 'Parámetros actualizados satisfactoriamente.', 'success',);
            })
            .catch(function (error) {
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            })
            .finally(function () {
              modalPdf.$refs.alert.hide();
              modalPdf.modalConfig = true;
            });
      }
    },
    actualizaFechasNotas: function () {
      var self = this
      if (this.fechasnotas.gestion !== '') {
        axios.get(this.$apiAdress + '/api/docentematerianotas/' + localStorage.sedeSeleccionada + '/' + this.fechasnotas.gestion + '/actualizafechasnotas?token=' + localStorage.getItem("api_token"))
            .then(function (response) {
              if (response.data.status) {
                self.fechasnotas = response.data.fechasnotas
              } else {
                self.fechasnotas = {
                  gestion: self.fechasnotas.gestion,
                  parcial1_fecha_inicio: '',
                  parcial1_fecha_fin: '',
                  parcial2_fecha_inicio: '',
                  parcial2_fecha_fin: '',
                  parcial3_fecha_inicio: '',
                  parcial3_fecha_fin: '',
                  examen_final_fecha_inicio: '',
                  examen_final_fecha_fin: '',
                  seg_instancia_fecha_inicio: '',
                  seg_instancia_fecha_fin: ''
                }
              }
            })
            .catch(function (error) {
              //modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
              self.fechasnotas = {
                gestion: self.fechasnotas.gestion,
                parcial1_fecha_inicio: '',
                parcial1_fecha_fin: '',
                parcial2_fecha_inicio: '',
                parcial2_fecha_fin: '',
                parcial3_fecha_inicio: '',
                parcial3_fecha_fin: '',
                examen_final_fecha_inicio: '',
                examen_final_fecha_fin: '',
                seg_instancia_fecha_inicio: '',
                seg_instancia_fecha_fin: ''
              }
            })
            .finally(function () {
              modalPdf.$refs.alert.hide();
            });
      }
    },
    grabarFechasNotas: function (event) {
      if (!modalPdf.$refs.frmFechasNotas.checkValidity()) {
        modalPdf.$refs.frmFechasNotas.classList.add('was-validated');
      } else {
        modalPdf.modalConfigFechas = false;
        modalPdf.$refs.alert.show('Grabando fechas');
        axios.post(this.$apiAdress + '/api/docentematerianotas/' + localStorage.sedeSeleccionada + '/grabarfechasnotas?token=' + localStorage.getItem("api_token"),
            modalPdf.fechasnotas
        )
            .then(function (response) {
              modalPdf.$refs.mensajeToast.makeToast('', 'Parámetros actualizados satisfactoriamente.', 'success',);
            })
            .catch(function (error) {
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            })
            .finally(function () {
              modalPdf.$refs.alert.hide();
              modalPdf.modalConfigFechas = true;
            });
      }
    },
    actualizarParcial: function () {
      this.parcial='0'
    },
  },
  computed: {},
  mounted() {
    modalPdf = this;
    this.$root.$on('sedeSeleccionada', data => {
      left.sede = data;
    });
    axios.get(this.$apiAdress + '/api/docentematerianotas/' + localStorage.sedeSeleccionada + '/gestiones?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          modalPdf.gestiones = response.data.gestiones;
          if (typeof modalPdf.$route.query.gestion === 'undefined' || modalPdf.$route.query.gestion === null) {
            modalPdf.gestion = response.data.mesActual;
          } else {
            modalPdf.gestion = modalPdf.$route.query.gestion
          }
          if (typeof modalPdf.$route.query.carrera_id === 'undefined' || modalPdf.$route.query.carrera_id === null) {
            modalPdf.carrera_id = "0";
          } else {
            modalPdf.carrera_id = modalPdf.$route.query.carrera_id;
          }
          modalPdf.actualizaMaterias();
          modalPdf.listarParametros(modalPdf.gestion)
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
  },
}
</script>